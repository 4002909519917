import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Translate } from 'react-translated';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import RootRef from '@material-ui/core/RootRef';
import Measure from 'react-measure';

import Box from './Box';
import AvailabilityButton from './AvailabilityButton';
import DatePicker from './DatePicker';
import GuestOptions from './GuestOptions';
import DateRange from '../utils/date-range';
import { emit } from '../utils/emitter';
import { createTheme, availableColors } from '../utils/theme';
import { isAdmin } from '../utils/admin';
import { isBooking, isTripadvisor } from '../utils/source';
import { Click } from '../utils/click';
import { log } from '../utils/logger';

const styles = (theme) => ({
  container: {
    maxWidth: theme.breakpoints.values.lg,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  dateColumn: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  guestColumn: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
});

class HotelPlugin extends Component {
  constructor() {
    super();

    this.state = {
      guestOptions: {
        roomCount: 1,
        adultCount: 2,
        childrenCount: 0
      },
      dateRange: new DateRange(),
      height: 0,
      loading: false
    };

    this.checkOutDatePickerRef = React.createRef();
  }

  componentDidMount() {
    log({
      name: 'Hotel Plugin',
      action: 'init',
      source: this.props.url,
      referrer: document.referrer
    });
  }

  expand = () => {
    emit('resize', '100%');
  };

  collapse = () => {
    const { height } = this.state;

    emit('resize', `${height}px`);
  };

  onCheckInDatePickerChange = (value) => {
    const { dateRange } = this.state;

    if (dateRange.isSameStart(value)) {
      this.collapse();

      return;
    }

    this.onDateRangeChange('start', value);

    this.checkOutDatePickerRef.current.open({
      currentTarget: ReactDOM.findDOMNode(this.checkOutDatePickerRef.current)
    });
  };

  onCheckOutDatePickerChange = (value) => {
    const { dateRange } = this.state;

    if (dateRange.isSameEnd(value)) {
      this.collapse();

      return;
    }

    this.onDateRangeChange('end', value);
    this.collapse();
  };

  onDateRangeChange = (type, value) => {
    const { dateRange } = this.state;
    dateRange[type] = value;

    this.setState((state) => ({ ...state, dateRange }));
  };

  onResize = (rect) => {
    const { height } = this.state;

    const newHeight = rect.bounds.height;

    if (height !== newHeight) {
      this.setState(
        (state) => ({ ...state, height: newHeight }),
        () => emit('resize', `${newHeight}px`)
      );
    }
  };

  onGuesOptionsChange = (guestOptions) => {
    this.setState((state) => ({ ...state, guestOptions }));
  };

  onAvailabilityCheck = () => {
    let { url } = this.props;
    const {
      dateRange,
      guestOptions: { roomCount, adultCount, childrenCount }
    } = this.state;

    log({
      name: 'Hotel Plugin',
      action: 'click',
      source: url,
      referrer: document.referrer
    });

    const startDate = DateRange.getObject(dateRange.start);
    const endDate = DateRange.getObject(dateRange.end);
    const hotelUrl = new URL(url);

    if (isBooking(url)) {
      hotelUrl.searchParams.set('checkin_monthday', startDate.monthday);
      hotelUrl.searchParams.set('checkin_month', startDate.month);
      hotelUrl.searchParams.set('checkin_year', startDate.year);
      hotelUrl.searchParams.set('checkout_monthday', endDate.monthday);
      hotelUrl.searchParams.set('checkout_month', endDate.month);
      hotelUrl.searchParams.set('checkout_year', endDate.year);
      hotelUrl.searchParams.set('no_rooms', roomCount);
      hotelUrl.searchParams.set('group_adults', adultCount);
      hotelUrl.searchParams.set('group_children', childrenCount);
      hotelUrl.searchParams.set(
        'room1',
        [...Array(adultCount).fill('A'), ...Array(childrenCount).fill(12)].join(',')
      );
    } else {
      hotelUrl.searchParams.set('adults', adultCount);
      hotelUrl.searchParams.set('inDay', startDate.monthday);
      hotelUrl.searchParams.set('inMonth', startDate.month);
      hotelUrl.searchParams.set('inYear', startDate.year);
      hotelUrl.searchParams.set('outDay', endDate.monthday);
      hotelUrl.searchParams.set('outMonth', endDate.month);
      hotelUrl.searchParams.set('outYear', endDate.year);
      hotelUrl.searchParams.set('rooms', roomCount);
      hotelUrl.searchParams.set(
        'staydates',
        [
          startDate.year,
          startDate.month,
          startDate.monthday,
          endDate.year,
          endDate.month,
          endDate.monthday
        ]
          .map((n) => n.toString().padStart(2, '0'))
          .join('_')
      );
    }

    const click = new Click();
    click.navigate(hotelUrl);
  };

  render() {
    const { classes, translate, position, theme, color, url } = this.props;
    const { guestOptions, dateRange, height, loading } = this.state;

    return (
      <MuiThemeProvider theme={createTheme({ position, theme, color, height })}>
        <Measure bounds onResize={this.onResize}>
          {({ measureRef }) => (
            <RootRef rootRef={measureRef}>
              <Box position={position}>
                <div className={classes.container}>
                  <Grid container spacing={16}>
                    <Grid className={classes.dateColumn} item xs>
                      <DatePicker
                        label={translate({ text: 'checkIn' })}
                        value={dateRange.start}
                        prevDisabled={!dateRange.isValidStart(dateRange.prevStart)}
                        nextDisabled={!dateRange.isValidStart(dateRange.nextStart)}
                        onOpen={this.expand}
                        onChange={this.onCheckInDatePickerChange}
                        onBackClick={() => this.onDateRangeChange('start', dateRange.prevStart)}
                        onNextClick={() => this.onDateRangeChange('start', dateRange.nextStart)}
                        minDate={dateRange.minStart}
                        maxDate={dateRange.maxStart}
                        disabled={loading}
                      />
                    </Grid>
                    <Grid className={classes.dateColumn} item xs>
                      <DatePicker
                        label={translate({ text: 'checkOut' })}
                        value={dateRange.end}
                        prevDisabled={!dateRange.isValidEnd(dateRange.prevEnd)}
                        nextDisabled={!dateRange.isValidEnd(dateRange.nextEnd)}
                        onOpen={this.expand}
                        onChange={this.onCheckOutDatePickerChange}
                        onBackClick={() => this.onDateRangeChange('end', dateRange.prevEnd)}
                        onNextClick={() => this.onDateRangeChange('end', dateRange.nextEnd)}
                        minDate={dateRange.minEnd}
                        maxDate={dateRange.maxEnd}
                        disabled={loading}
                        ref={this.checkOutDatePickerRef}
                      />
                    </Grid>
                    <Grid className={classes.guestColumn} item xs>
                      <GuestOptions
                        translate={translate}
                        label={translate({ text: 'guests' })}
                        hideChildrenCounter={isTripadvisor(url)}
                        roomCount={guestOptions.roomCount}
                        adultCount={guestOptions.adultCount}
                        childrenCount={guestOptions.childrenCount}
                        onOpen={this.expand}
                        onClose={this.collapse}
                        onChange={this.onGuesOptionsChange}
                        disabled={loading}
                      />
                    </Grid>
                    <Grid item xs>
                      <AvailabilityButton onClick={this.onAvailabilityCheck} disabled={loading}>
                        {loading && <CircularProgress color="inherit" size={26} />}
                        {!loading && <Translate text="checkAvailability" />}
                      </AvailabilityButton>
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </RootRef>
          )}
        </Measure>
      </MuiThemeProvider>
    );
  }
}

HotelPlugin.propTypes = {
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['top', 'bottom']),
  theme: PropTypes.oneOf(['light', 'dark']),
  color: PropTypes.oneOf(Object.keys(availableColors))
};

HotelPlugin.defaultProps = {
  url: process.env.REACT_APP_HOTEL_URL,
  position: 'bottom',
  theme: 'light',
  color: 'blue'
};

export default withStyles(styles)(HotelPlugin);
