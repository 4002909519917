import React from 'react';
import PropTypes from 'prop-types';
import { Provider, Translator } from 'react-translated';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import HotelPlugin from './components/HotelPlugin';
import translation, { availableLocales, detectLanguage, mapLocaleToMoment } from './utils/translation';
import { setAdmin } from './utils/admin';

const styles = () => ({
  '@global': {
    body: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      userSelect: 'none'
    }
  }
});

const App = props => {
  const language = detectLanguage(props.language);
  moment.locale(mapLocaleToMoment(language));

  if (props.admin || language === 'nl') {
    setAdmin();
  }

  return (
    <Provider language={language} translation={translation}>
      <Translator>
        {({ translate }) => (
          <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
            <CssBaseline />
            <HotelPlugin {...props} translate={translate} />
          </MuiPickersUtilsProvider>
        )}
      </Translator>
    </Provider>
  );
};

App.propTypes = {
  admin: PropTypes.bool,
  language: PropTypes.oneOf(Object.keys(availableLocales))
};

export default withStyles(styles)(App);
